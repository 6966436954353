<template>
	<div class="staff-list" style="transition: all .3s;" ref="el-col">
		<div class="my-head is-init"><span style="display: inline-block; padding: 5px 10px;">成员列表</span></div>
		<div v-loading="staff.loading" class="list-wrap">
			<p class="collapse" @click="handleCollapse">
				<i v-if="isCollapse" class="el-icon-arrow-left"></i>
				<i v-else class="el-icon-arrow-right"></i>
			</p>
			<ul class="my-lists hidden" @scroll="onLoadStaff">
				<li v-for="(item, index) in staff.list" :class="{ active: staff_id == item.user_id }" @click="staff_id = item.user_id">
					<img class="avatar" :src="item.avatar" alt="" />
					<span>{{ item.name || '--' }}</span>
					<img class="sex" v-if="item.gender == 2" src="../../assets/images/girl.png" alt="" />
					<img class="sex" v-else-if="item.gender == 1" src="../../assets/images/boy.png" alt="" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { reqGetChatStaffList } from '@/api/index';
export default {
	data() {
		return {
			staff: {
				list: [],
				loading: false,
				total: 0,
				disabled: false
			},
			page: 1,
			isCollapse: true,
			staff_id: ''
		};
	},
	watch: {
		staff_id(val) {
			this.$bus.$emit('setStaffId', val);
		}
	},
	created() {
		this.getList();
	},
	methods: {
		getList() {
			this.staff.loading = true;
			reqGetChatStaffList({ page: this.page, limit: 15, keyword: this.keyword }).then(res => {
				this.staff.list = this.staff.list.concat(res.data);
				this.staff.total = res.count || 0;
				this.staff.loading = false;
			});
		},
		onLoadStaff(e) {
			const oScroll = e.target;
			if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight && !this.staff.loading) {
				if (this.staff.list.length < this.staff.total) {
					this.page++;
					this.getList();
				} else {
					return false;
				}
			}
		},
		handleCollapse() {
			if (this.isCollapse) {
				this.$nextTick(() => {
					this.$refs['el-col'].style.width = '77px';
				});
			} else {
				this.$nextTick(() => {
					this.$refs['el-col'].style.width = '15%';
				});
			}
			this.isCollapse = !this.isCollapse;
		}
	}
};
</script>

<style lang="less" scoped>
.staff-list {
	width: 15%;
	padding: 0 7.5px;
	.my-head {
		font-size: 14px;
		color: #666;
		font-weight: bold;
		line-height: 2;
		background-color: #e0e2e5;
		border-radius: 3px;
		margin-bottom: 1px;

		&.is-center {
			display: flex;
			align-content: center;
			justify-content: space-around;

			> span {
				cursor: pointer;
				padding: 5px 10px;

				&.active {
					color: #133ab3;
				}

				&:hover {
					color: #133ab3;
				}
			}
		}

		&.is-active {
			color: #133ab3;
		}
	}
	.list-wrap {
		position: relative;
		.collapse {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.4);
			padding: 5px;
			cursor: pointer;
			display: none;
			i {
				font-size: 20px;
				color: #fff;
			}
		}

		&:hover .collapse {
			display: flex;
		}
	}
	.my-lists {
		height: calc(100vh - 275px);
		overflow-x: hidden;
		overflow-y: auto;

		> li {
			line-height: 2em;
			display: flex;
			align-items: center;
			color: #333;
			font-size: 14px;
			padding: 7px 10px;
			margin-top: 10px;
			cursor: pointer;

			&:hover {
				background-color: #ecf5ff;
			}

			&.active {
				background-color: #e5ebfb;
			}

			.avatar {
				width: 42px;
				height: 42px;
				margin-right: 30px;
				border-radius: 50%;
			}

			.sex {
				width: 15px;
			}

			> span {
				display: flex;
				flex-direction: column;
				margin-right: 10px;

				.name {
					color: #6881ec;
				}

				span {
					line-height: 1.5;
				}
			}
		}
	}

	.hidden {
		white-space: nowrap;
	}
}
</style>
