<template>
	<div class="list">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p>
				<span class="black">
					1、使用此功能请先开通会话内容存档
					<span
						style="color: rgb(104, 129, 236);cursor: pointer;"
						@click="
							() => {
								$bus.$emit('showHelp', 20);
							}
						"
					>
						（如何开通）
					</span>
					并完成存档配置
					<span
						style="color: rgb(104, 129, 236);cursor: pointer;"
						@click="
							() => {
								$router.push('/session/setting');
							}
						"
					>
						（前往配置）
					</span>
				</span>
			</p>
			<p><span class="black">2、成员列表显示在企业微信后台开启范围选中的成员，未选中的成员将不尽兴内容存档。</span></p>
			<p>
				<span class="black">
					3、“内部”消息指企业成员与成员之间的消息，“外部”指成员与客户之间的消息，“群聊”则包括内外部群聊，其中内部群聊统一称为“内部群聊”，不显示原有群聊名称。
				</span>
			</p>
			<p><span class="black">4、切换企业后，请先检查存档配置是否已配置。</span></p>
		</div>
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="消息列表" name="1"><Message></Message></el-tab-pane>
			<el-tab-pane label="监控员工" name="2"><Monitor></Monitor></el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import Monitor from './Monitor.vue';
import Message from './Message.vue';
export default {
	components: {
		Monitor,
		Message
	},
	data() {
		return {
			showCourse: false,
			activeName: '1'
		};
	}
};
</script>

<style lang="less" scoped>
.list {
	width: 100%;
	position: relative;
	overflow: hidden;

	> div + div {
		margin-top: 15px;
	}

	.content {
		width: 1200px;
		background-color: gainsboro;
	}
}
</style>
