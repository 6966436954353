<template>
	<div class="monitor">
		<div class="flex">
			<el-input size="small" style="width: 200px;" v-model="keyword" placeholder="请输入成员"></el-input>
			<el-date-picker
				size="small"
				v-model="date"
				style="width: 260px;margin: 0 10px;"
				type="daterange"
				range-separator="-"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button size="small" class="btn-blue">查找</el-button>
			<el-button
				size="small"
				class="btn-white"
				@click="
					() => {
						date = [];
						keyword = '';
					}
				"
			>
				清空
			</el-button>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				:data="tableData"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
			>
				<el-table-column prop="name" label="成员姓名"></el-table-column>
				<el-table-column prop="wxk_staff_id" label="所属部门"></el-table-column>
				<el-table-column prop="entry_num" label="状态"></el-table-column>
				<el-table-column prop="praise" label="首次启用时间"></el-table-column>
				<el-table-column prop="visits" label="添加人"></el-table-column>
				<el-table-column prop="visits" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEdit(scope.row.id)">停用</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						page = 1;
						getList();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getList();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 40, 80]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			keyword: '',
			date: [],
			page: 1,
			limit: 10,
			total: 0,
			tableData: [],
			loading: false
		};
	},
	methods: {}
};
</script>

<style lang="less" scoped>
.monitor {
	width: 100%;
	position: relative;
	overflow: hidden;

	.flex {
		display: flex;
	}

	> div + div {
		margin-top: 15px;
	}
}
</style>
