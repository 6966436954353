<template>
	<div class="message">
		<div class="row">
			<staff-list></staff-list>
			<chat-type></chat-type>
			<chat-record></chat-record>
		</div>
	</div>
</template>

<script>
import StaffList from './StaffList.vue';
import ChatType from './ChatType.vue';
import ChatRecord from './ChatRecord.vue';
export default {
	components: {
		StaffList,
		ChatType,
		ChatRecord
	}
};
</script>

<style lang="less" scoped>
.message {
	width: 100%;
	position: relative;
	overflow: hidden;
	.row {
		display: flex;
		margin: 0 -7.5px;
	}
}
</style>
