<template>
	<div class="chat-type">
		<div class="list-wrap">
			<div class="my-head is-center">
				<span v-for="item in options" :class="{ active: chat_type == item.value }" @click="chat_type = item.value">{{ item.name }}</span>
			</div>
			<ul v-loading="chat.loading" class="my-lists" @scroll="onLoadChat">
				<template v-if="chat.list.length">
					<li v-for="(item, index) in chat.list" :class="{ active: seq == item.seq }" @click="handleChecked(item)">
						<img v-if="chat_type != 3" class="avatar" style="margin-right: 10px;" :src="item.avatar" alt="" />
						<span style="width: 100%;">
							<span class="name">{{ item.name }}</span>
							<span v-if="item.msgtype == 'text'" class="ellipsis" style="width: calc(100% - 50px);">{{ item.content }}</span>
							<span v-else class="ellipsis" style="width: calc(100% - 50px);">
								【{{
									item.msgtype == 'image'
										? '图片'
										: item.msgtype == 'voice'
										? '语音'
										: item.msgtype == 'video'
										? '视频'
										: item.msgtype == 'file'
										? '文件'
										: item.msgtype == 'link'
										? '链接'
										: item.msgtype == 'weapp'
										? '小程序'
										: item.msgtype == 'card'
										? '名片'
										: item.msgtype == 'redpacket'
										? '红包'
										: item.msgtype == 'external_redpacket'
										? '外部红包'
										: ''
								}}】
							</span>
						</span>
					</li>
				</template>
				<template v-else-if="!chat.loading && chat.list.length == 0">
					<div class="empty">暂无记录</div>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import { reqGetStaffChatMsg } from '@/api/index';
export default {
	data() {
		return {
			chat: {
				list: [],
				loading: false,
				total: 0,
				disabled: true
			},
			page: 1,
			staff_id: '',
			chat_type: 1,
			seq: '',
			options: [{ name: '内部', value: 1 }, { name: '外部', value: 2 }, { name: '群聊', value: 3 }]
		};
	},
	watch: {
		chat_type(val) {
			this.$bus.$emit('setChatType', val);
			if (this.staff_id) {
				this.page = 1;
				this.chat.list = [];
				this.getList();
			}
		},
		staff_id(val) {
			this.seq = '';
			this.page = 1;
			this.chat.list = [];
		}
	},
	mounted() {
		this.$bus.$on('setStaffId', staff_id => {
			this.staff_id = staff_id;
			this.getList();
		});
	},
	beforeDestroy() {
		this.$bus.$off('setStaffId');
	},
	methods: {
		getList() {
			this.chat.loading = true;
			reqGetStaffChatMsg({
				staff_id: this.staff_id,
				chat_type: this.chat_type,
				page: this.page,
				limit: 10
			}).then(res => {
				this.chat.list = this.chat.list.concat(res.data);
				this.chat.total = res.count || 0;
				this.chat.loading = false;
			});
		},
		onLoadChat(e) {
			const oScroll = e.target;
			if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight && !this.chat.loading) {
				if (this.chat.list.length < this.chat.total) {
					this.page++;
					this.getList();
				} else {
					return false;
				}
			}
		},
		handleChecked(option) {
			this.seq = option.seq;
			this.$bus.$emit('setOption', option);
		}
	}
};
</script>

<style lang="less" scoped>
.empty {
	font-size: 14px;
	color: #999;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chat-type {
	padding: 0 7.5px;
	width: 22%;
	overflow: hidden;
	.my-head {
		font-size: 14px;
		color: #666;
		font-weight: bold;
		line-height: 2;
		background-color: #e0e2e5;
		border-radius: 3px;
		margin-bottom: 1px;

		&.is-center {
			display: flex;
			align-content: center;
			justify-content: space-around;

			> span {
				cursor: pointer;
				padding: 5px 10px;

				&.active {
					color: #133ab3;
				}

				&:hover {
					color: #133ab3;
				}
			}
		}

		&.is-active {
			color: #133ab3;
		}
	}
	.my-lists {
		height: calc(100vh - 275px);
		overflow-x: hidden;
		overflow-y: auto;

		> li {
			line-height: 2em;
			display: flex;
			align-items: center;
			color: #333;
			font-size: 14px;
			padding: 7px 10px;
			margin-top: 10px;
			cursor: pointer;

			&:hover {
				background-color: #ecf5ff;
			}

			&.active {
				background-color: #e5ebfb;
			}

			.avatar {
				width: 42px;
				height: 42px;
				margin-right: 30px;
				border-radius: 50%;
			}

			.sex {
				width: 15px;
			}

			> span {
				display: flex;
				flex-direction: column;
				margin-right: 10px;

				.name {
					color: #6881ec;
				}

				span {
					line-height: 1.5;
				}
			}
		}
	}
}
</style>
