<template>
	<div class="chat-record">
		<div class="my-head is-center is-init">
			<span v-for="item in options" :class="{ active: msgtype == item.value }" @click="msgtype = item.value">{{ item.name }}</span>
		</div>
		<div class="wrap" v-loading="loading">
			<div class="search">
				<el-input class="right" size="small" v-model="keyword" placeholder="请输入聊天内容"></el-input>
				<el-date-picker
					size="small"
					style="width: 220px;margin:0  10px;"
					v-model="date"
					type="daterange"
					value-format="yyyy-MM-dd"
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button size="small" class="btn-blue" @click="handleSearch">搜索</el-button>
			</div>
			<div class="scroll" @scroll="onload">
				<ul class="main-wrap" v-if="list.length">
					<li v-for="(item, index) in list">
						<img class="avatar" :src="item.user_avatar" alt="" />
						<span v-show="isSearch" class="context-btn" @click="handleContext(item)">查看上下文</span>
						<div style="width: 100%;">
							<p class="flex">
								<span class="name">{{ item.user_name }}</span>
								<span class="date right">{{ item.create_data }}</span>
							</p>
							<div v-if="item.msgtype == 'text'" class="text">
								<span style="display: inline-block;max-width: 500px;">{{ item.content }}</span>
							</div>
							<div v-else-if="item.msgtype == 'image'" style="width: 80%;">
								<el-image
									fit="cover"
									:z-index="9999"
									style="width: 150px; height: 150px"
									:src="item.content"
									@click.stop="$closeImg"
									:preview-src-list="[item.content]"
								></el-image>
							</div>
							<div v-else-if="item.msgtype == 'voice'" style="width: 80%;margin-top: 5px;"><VoicePlayer :url="item.content" :time="item.voice_size" /></div>
							<div v-else-if="item.msgtype == 'video'" style="width: 80%;">
								<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
							</div>
							<div v-else-if="item.msgtype == 'file'" style="width: 80%;">
								<template v-if="matchType(item.content) == 'image'">
									<el-image
										fit="cover"
										:z-index="9999"
										style="width: 150px; height: 150px"
										:src="item.content"
										@click.stop="$closeImg"
										:preview-src-list="[item.content]"
									></el-image>
								</template>
								<template v-else-if="matchType(item.content) == 'video'">
									<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
								</template>
								<template v-else-if="matchType(item.content) == 'audio'">
									<audio controls>
										<source :src="item.content" type="audio/ogg" />
										<source :src="item.content" type="audio/mpeg" />
										<source :src="item.content" type="audio/wav" />
										您的浏览器不支持 audio 元素。
									</audio>
								</template>
								<template v-else>
									<img
										v-if="matchType(item.content) == 'pdf'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/pdf.png"
										alt=""
									/>
									<img
										v-else-if="matchType(item.content) == 'excel'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/excel.png"
										alt=""
									/>
									<img
										v-else-if="matchType(item.content) == 'ppt'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/ppt.png"
										alt=""
									/>
									<img
										v-else-if="matchType(item.content) == 'txt'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/txt.png"
										alt=""
									/>
									<img
										v-else-if="matchType(item.content) == 'word'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/word.png"
										alt=""
									/>
									<img
										v-else-if="matchType(item.content) == 'zip'"
										style="cursor: pointer;margin-top: 10px;"
										@click="downloadFile(item)"
										src="../../assets/file_type/zip.png"
										alt=""
									/>
									<span style="display: block;">{{ item.filename }}</span>
								</template>
							</div>
							<div v-else-if="item.msgtype == 'link'" class="text">
								<span style="color: #0086B3;display: inline-block;max-width: 500px;">{{ item.link_url }}</span>
							</div>
							<div v-else-if="item.msgtype == 'weapp'" class="weapp">
								<p>
									<img src="../../assets/favicon.png" alt="" />
									<span>{{ item.displayname }}</span>
								</p>
								<h4>{{ item.title }}</h4>
								<div class="desc">{{ item.description }}</div>
								<p class="line"></p>
								<p class="icon">
									<i class="el-icon-link"></i>
									<span>小程序</span>
								</p>
							</div>
							<div v-else-if="item.msgtype == 'redpacket'">【红包】</div>
							<div v-else-if="item.msgtype == 'external_redpacket'">【外部红包】</div>
							<div v-else-if="item.msgtype == 'card'">
								名片
								<!-- <div class="card">
									<div>
										<p>
											<span class="company">{{ item.card_corpname }}</span>
											<span class="name">{{ item.user_name }}</span>
										</p>
										<img class="avatar" :src="item.user_avatar" alt="" />
									</div>
									<p class="line"></p>
									<p>个人名片</p>
								</div> -->
							</div>
						</div>
						<p class="line"></p>
					</li>
				</ul>
				<div
					v-else
					style="color: #999;height: 100%;display: flex;
				align-items: center;justify-content: center;"
				>
					暂无记录
				</div>
			</div>
			<div :class="{ 'context-scroll': true, in: status == 'in', out: status == 'out' }" v-show="show">
				<el-page-header @back="goBack"></el-page-header>
				<div class="scroll-wrap" @scroll="scrollEvent" v-loading="scrollLoading">
					<ul class="main-wrap">
						<li v-for="(item, index) in contextList" :ref="item.seq.toString()" :class="{ active: seq == item.seq }">
							<img class="avatar" :src="item.user_avatar" alt="" />
							<div style="width: 100%;">
								<p class="flex">
									<span class="name">{{ item.user_name }}</span>
									<span class="date right">{{ item.create_data }}</span>
								</p>
								<div v-if="item.msgtype == 'text'" class="text">{{ item.content }}</div>
								<div v-else-if="item.msgtype == 'image'" style="width: 80%;">
									<el-image
										fit="cover"
										:z-index="9999"
										style="width: 150px; height: 150px"
										:src="item.content"
										@click.stop="$closeImg"
										:preview-src-list="[item.content]"
									></el-image>
								</div>
								<div v-else-if="item.msgtype == 'voice'" style="width: 80%;margin-top: 5px;"><VoicePlayer :url="item.content" :time="item.voice_size" /></div>
								<div v-else-if="item.msgtype == 'video'" style="width: 80%;">
									<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
								</div>
								<div v-else-if="item.msgtype == 'file'" style="width: 80%;">
									<template v-if="matchType(item.content) == 'image'">
										<el-image
											fit="cover"
											:z-index="9999"
											style="width: 150px; height: 150px"
											:src="item.content"
											@click.stop="$closeImg"
											:preview-src-list="[item.content]"
										></el-image>
									</template>
									<template v-else-if="matchType(item.content) == 'video'">
										<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
									</template>
									<template v-else-if="matchType(item.content) == 'audio'">
										<audio controls>
											<source :src="item.content" type="audio/ogg" />
											<source :src="item.content" type="audio/mpeg" />
											<source :src="item.content" type="audio/wav" />
											您的浏览器不支持 audio 元素。
										</audio>
									</template>
									<template v-else>
										<img
											v-if="matchType(item.content) == 'pdf'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/pdf.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'excel'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/excel.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'ppt'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/ppt.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'txt'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/txt.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'word'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/word.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'zip'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/zip.png"
											alt=""
										/>
										<span style="display: block;">{{ item.filename }}</span>
									</template>
								</div>
								<div v-else-if="item.msgtype == 'link'" style="width: 80%;">
									<span style="color: #0086B3;">{{ item.link_url }}</span>
								</div>
								<div v-else-if="item.msgtype == 'weapp'" class="weapp">
									<p>
										<img src="../../assets/favicon.png" alt="" />
										<span>{{ item.displayname }}</span>
									</p>
									<h4>{{ item.title }}</h4>
									<div class="desc">{{ item.description }}</div>
									<p class="line"></p>
									<p class="icon">
										<i class="el-icon-link"></i>
										<span>小程序</span>
									</p>
								</div>
								<div v-else-if="item.msgtype == 'redpacket'">【红包】</div>
								<div v-else-if="item.msgtype == 'external_redpacket'">【外部红包】</div>
								<div v-else-if="item.msgtype == 'card'">【名片】</div>
							</div>
							<p class="line"></p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script src="https://cdn.bootcdn.net/ajax/libs/audiojs/1.0.1/audio.min.js"></script>
<script>
import { reqGetChatArchiving } from '@/api/index';
import Video from '@/components/video';
import VoicePlayer from './VoicePlayer.vue';
import download from '@/util/download.js';
export default {
	components: {
		Video,
		VoicePlayer
	},
	data() {
		return {
			keyword: '',
			page: 1,
			limit: 10,
			total: 0,
			msgtype: '',
			staff_id: '',
			chat_type: 1,
			date: [],
			contacts_id: '',
			loading: false,
			disabled: true,
			seq: '',
			sort: 1,
			list: [],
			up: {
				list: [],
				limit: 10,
				total: 0
			},
			down: {
				list: [],
				limit: 10,
				total: 0
			},
			isSearch: false,
			status: '',
			show: false,
			scrollLoading: false,
			options: [
				{ name: '全部', value: '' },
				{ name: '文本', value: 'text' },
				{ name: '图片', value: 'image' },
				{ name: '语音', value: 'voice' },
				{ name: '视频', value: 'video' },
				{ name: '文件', value: 'file' },
				{ name: '链接', value: 'link' },
				{ name: '小程序', value: 'weapp' }
			],
			contextList: []
		};
	},
	computed: {
		reqFlag() {
			if (this.staff_id && this.chat_type && this.contacts_id) {
				return true;
			} else {
				return false;
			}
		},
		start_time() {
			if (this.date) {
				return this.date[0];
			} else {
				return '';
			}
		},
		end_time() {
			if (this.date) {
				return this.date[1];
			} else {
				return '';
			}
		}
	},
	watch: {
		msgtype() {
			this.goBack();
			this.page = 1;
			this.list = [];
			if (this.reqFlag) {
				this.getChatList();
			}
		},
		staff_id() {
			this.goBack();
			this.contacts_id = '';
			this.list = [];
		},
		keyword(val) {
			if (!val) {
				this.goBack();
				if (this.reqFlag) {
					this.page = 1;
					this.list = [];
					this.getChatList();
				}
			}
		},
		date(val) {
			if (!val || !val.length) {
				this.goBack();
				if (this.reqFlag) {
					this.getChatList();
				}
			}
		}
	},
	mounted() {
		this.$bus.$on('setStaffId', staff_id => (this.staff_id = staff_id));
		this.$bus.$on('setChatType', chat_type => {
			this.goBack();
			this.chat_type = chat_type;
		});
		this.$bus.$on('setOption', option => {
			this.contacts_id = option.roomid || option.uid;
			this.page = 1;
			this.list = [];
			if (this.reqFlag) {
				this.getChatList();
			}
		});
	},
	beforeDestroy() {
		this.$bus.$off('setStaffId');
		this.$bus.$off('setChatType');
	},
	methods: {
		// 下载文件
		downloadFile(data) {
			const url = data.content;
			const fileName = data.filename.substr(0, data.filename.lastIndexOf('.'));
			var x = new XMLHttpRequest();
			x.open('GET', url, true);
			x.responseType = 'blob';
			x.onload = e => {
				download(x.response, fileName);
			};
			x.send();
		},
		// 获取聊天记录列表
		getChatList(isSearch = false) {
			this.loading = true;
			reqGetChatArchiving({
				staff_id: this.staff_id,
				contacts_id: this.contacts_id,
				chat_type: this.chat_type,
				msgtype: this.msgtype,
				page: this.page,
				limit: 10,
				keyword: this.keyword,
				start_time: this.start_time,
				end_time: this.end_time,
				seq: this.seq
			}).then(res => {
				this.total = res.count || 0;
				this.list = this.list.concat(res.data);
				this.isSearch = isSearch;
				this.loading = false;
			});
		},
		onload(e) {
			const oScroll = e.target;
			if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight && !this.loading) {
				if (this.list.length < this.total) {
					this.page++;
					this.getChatList();
				} else {
					return false;
				}
			}
		},
		// 搜索
		handleSearch() {
			if ((this.keyword && this.reqFlag) || (this.date && this.date.length && this.reqFlag)) {
				this.page = 1;
				this.list = [];
				this.getChatList(true);
			}
		},
		handleContext(data) {
			this.scrollLoading = true;
			this.status = 'in';
			this.show = true;
			this.seq = data.seq;
			Promise.all([
				new Promise(resolve => {
					reqGetChatArchiving({
						staff_id: this.staff_id,
						contacts_id: this.contacts_id,
						chat_type: this.chat_type,
						msgtype: this.msgtype,
						page: 1,
						limit: 10,
						seq: this.seq,
						sort: 1
					}).then(res => {
						this.down.list = res.data;
						this.down.total = res.count || 0;
						resolve();
					});
				}),
				new Promise(resolve => {
					reqGetChatArchiving({
						staff_id: this.staff_id,
						contacts_id: this.contacts_id,
						chat_type: this.chat_type,
						msgtype: this.msgtype,
						page: 1,
						limit: 10,
						seq: this.seq,
						sort: 2
					}).then(res => {
						this.up.list = res.data;
						this.up.total = res.count || 0;
						resolve();
					});
				})
			]).then(res => {
				let obj = {};
				this.contextList = this.up.list.concat(this.down.list);
				this.contextList = this.contextList.reduce((cur, next) => {
					obj[next.seq] ? '' : (obj[next.seq] = true && cur.push(next));
					return cur;
				}, []);
				this.scrollLoading = false;
				this.$nextTick(() => {
					document.querySelector('.scroll-wrap').scrollTop = this.$refs[this.seq][0].offsetTop;
				});
			});
		},
		getContextList(oScroll, init) {
			this.scrollLoading = true;
			var page;
			if (this.sort == 1) {
				page = this.down.page;
			} else {
				page = this.up.page;
			}
			reqGetChatArchiving({
				staff_id: this.staff_id,
				contacts_id: this.contacts_id,
				chat_type: this.chat_type,
				msgtype: this.msgtype,
				page,
				limit: 10,
				seq: this.seq,
				sort: this.sort
			}).then(res => {
				let obj = {};
				if (this.sort == 1) {
					this.down.list = this.down.list.concat(res.data);
					this.down.total = res.count || 0;
				} else {
					this.up.list = res.data.concat(this.up.list);
					this.up.total = res.count || 0;
				}
				this.contextList = this.up.list.concat(this.down.list);
				this.contextList = this.contextList.reduce((cur, next) => {
					obj[next.seq] ? '' : (obj[next.seq] = true && cur.push(next));
					return cur;
				}, []);
				if (Boolean(oScroll) && this.sort == 2) {
					const startSH = oScroll.scrollHeight;
					this.$nextTick(() => {
						const endSH = oScroll.scrollHeight;
						oScroll.scrollTop = endSH - startSH;
					});
				}
				this.scrollLoading = false;
			});
		},
		goBack() {
			this.status = 'out';
			this.seq = '';
			this.sort = 1;
			setTimeout(() => {
				this.up = {
					list: [],
					page: 1,
					total: 0
				};
				this.down = {
					list: [],
					page: 1,
					total: 0
				};
				this.show = false;
			}, 300);
		},
		scrollEvent(e) {
			const oScroll = e.target;
			if (oScroll.scrollTop <= 100 && !this.scrollLoading) {
				this.sort = 2;
				if ((this.reqFlag && this.up.list.length < this.up.total) || (this.reqFlag && this.up.total == 0)) {
					this.up.page++;
					this.getContextList(oScroll);
				} else {
					return;
				}
			} else if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight && !this.scrollLoading) {
				this.sort = 1;
				if ((this.reqFlag && this.down.list.length < this.down.total) || (this.reqFlag && this.down.total == 0)) {
					this.down.page++;
					this.getContextList(oScroll);
				} else {
					return;
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.card {
	padding: 15px 10px;
	width: 210px;
	height: 90px;
	background-color: #fff;
	border-radius: 5px;
	border: 1px solid #f0f2f5;
	display: flex;
	flex-direction: column;
	.line {
		width: 100%;
		height: 1px;
		background-color: #f0f2f5;
	}
}
.chat-record {
	flex-grow: 1;
	.wrap {
		position: relative;
		width: calc(100% - 28px);
		padding: 0 10px;
		background-color: rgb(245, 245, 245);
		padding-top: 15px;
		.search {
			display: flex;
			.el-input {
				width: 200px;
			}
		}

		.main {
			height: calc(100vh - 335px);
			overflow-x: hidden;
			overflow-y: auto;
			margin-top: 15px;
		}
	}

	.scroll,
	.context-scroll,
	.scroll-wrap {
		position: relative;
		height: calc(100vh - 335px);
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 15px;
		background-color: rgb(245, 245, 245);
		.main-wrap {
			width: calc(100% - 100px);
			margin: 0 auto;
			border-top: 1px solid #ececec;
			> li {
				position: relative;
				display: flex;
				font-size: 14px;
				padding: 25px 10px;

				&.active {
					background-color: #e5ebfb;
				}
				.avatar {
					width: 42px;
					height: 42px;
					object-fit: cover;
					margin-right: 10px;
					border-radius: 5px;
				}
				.flex {
					display: flex;
					color: #999;
					font-size: 12px;
				}
				.text {
					width: 80%;
					line-height: 1.5;
					border-radius: 5px;
					margin-top: 5px;
					&.is-staff {
						// background-color: #c7d3f2;
					}
					&.is-custom {
						// background-color: #fff;
					}
				}

				.context-btn {
					position: absolute;
					top: 50px;
					right: 0px;
					font-size: 12px;
					color: #1352b1;
					cursor: pointer;
					display: none;
					&:hover {
						opacity: 0.7;
					}
				}
				&:hover .context-btn {
					display: block;
				}
			}
			> li + li {
				&::after {
					top: 0;
					left: 52px;
					position: absolute;
					content: '';
					width: calc(100% - 50px);
					height: 1px;
					background-color: #ececec;
				}
			}
		}
	}
	.context-scroll {
		width: calc(100% - 20px);
		position: absolute;
		top: 47px;
		z-index: 10;
		&.in {
			animation: drawer-in 0.3s;
		}
		&.out {
			animation: drawer-out 0.3s 1ms;
		}
		.main-wrap {
			border: none;
		}
		.el-page-header {
			width: calc(100% - 110px);
			line-height: 35px;
			margin-left: 50px;
			border-bottom: 1px solid #ececec;
		}
	}
	.scroll-wrap {
		margin-top: 0;
		height: calc(100vh - 332px);
	}

	.my-head {
		font-size: 14px;
		color: #666;
		font-weight: bold;
		line-height: 2;
		background-color: #e0e2e5;
		border-radius: 3px;
		margin-bottom: 1px;

		&.is-center {
			display: flex;
			align-content: center;
			justify-content: space-around;

			> span {
				cursor: pointer;
				padding: 5px 10px;

				&.active {
					color: #133ab3;
				}

				&:hover {
					color: #133ab3;
				}
			}
		}

		&.is-active {
			color: #133ab3;
		}
	}
	.over_scroll_1 {
		position: relative;
		width: 100%;
		overflow-x: hidden;
		height: calc(100vh - 360px);
	}
	.chats {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		right: -7px;
		> li {
			display: flex;
			font-size: 14px;
			margin-top: 12px;
			.avatar {
				width: 42px;
				height: 42px;
				border-radius: 50%;
				object-fit: cover;
				margin-right: 10px;
			}
			.flex {
				display: flex;
			}
			.text {
				padding: 10px;
				line-height: 1.5;
				border-radius: 5px;
				margin-top: 5px;
				&.is-staff {
					background-color: #c7d3f2;
				}
				&.is-custom {
					background-color: #fff;
				}
			}
		}
	}
	.over_scroll_2 {
		position: relative;
		overflow-x: hidden;
		height: calc(100vh - 360px);
	}
	.chats-list {
		width: calc(100% - 20px);
		padding: 10px;
		background-color: #fff;
		margin-top: 10px;
		border-radius: 3px;
		height: calc(100% - 30px);
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		right: -7px;
		> li {
			display: flex;
			font-size: 14px;
			padding: 10px 0;
			cursor: pointer;
			img {
				width: 35px;
				height: 35px;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 10px;
			}
			> span {
				line-height: 1.5;
			}
			&:hover {
				background-color: #ecf5ff;
			}

			&.active {
				background-color: #e5ebfb;
			}
		}
		li + li {
			border-top: 1px solid #f0f2f5;
		}
	}

	.weapp {
		max-width: 210px;
		padding: 10px 10px 5px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		background-color: #fff;
		margin-top: 5px;
		> p {
			display: flex;
			align-items: center;
			> img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				object-fit: cover;
				margin-right: 10px;
			}
			> span {
				font-size: 12px;
				color: #aaa;
			}
		}
		> h4 {
			font-weight: 400;
			margin: 10px 0;
			line-height: 1.5;
		}
		.desc {
			background-color: #f0f2f5;
			padding: 20px 15px;
			height: 100px;
			font-size: 16px;
			font-weight: bold;
		}
		.line {
			height: 1px;
			background-color: #eee;
			margin: 10px 0 5px;
		}
		.icon {
			display: flex;
			align-items: center;
			font-size: 12px;
			i {
				margin-right: 5px;
				font-size: 14px;
			}
		}
	}
}
</style>
<style>
@keyframes drawer-in {
	0% {
		transform: translate(100%);
	}
	100% {
		transform: translate(0);
	}
}
@keyframes drawer-out {
	0% {
		transform: translate(0);
	}
	100% {
		transform: translate(100%);
	}
}
</style>
